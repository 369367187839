import OSM from "ol/source/OSM";
import TileLayer from "ol/layer/Tile";
import GeoJSON from "ol/format/GeoJSON";
import Map from "ol/Map";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import View from "ol/View";
import { transform } from "ol/proj.js";
import { Style, Fill, Stroke } from "ol/style";
import { viridis } from "scale-color-perceptual";

const center = [-77.13951846201199, 39.077112793026686];

function clamp(x, min, max) {
  if (x < min) {
    return min;
  }
  if (x > max) {
    return max;
  }
  return x;
}

const ageStyle = (feature, _resolution) => {
  const min = 1900;
  const max = 2023;
  const t_free = (feature.get("YEAR_BUILT") - min) / (max - min);
  const t = clamp(t_free, 0, 1);
  const fill = viridis(t);
  return new Style({
    fill: new Fill({ color: fill }),
  });
};

const leadCutoffStyle = (feature, _resolution) => {
  const fill = feature.get("YEAR_BUILT") >= 1978 ? "#0080ff80" : "#ff000080";
  return new Style({
    fill: new Fill({ color: fill }),
  });
};

function selectedStyle(styleF) {
  return (feature, resolution) => {
    const out = styleF(feature, resolution);
    out.setStroke(new Stroke({ color: "#a33", width: 3 }));
    return out;
  };
}

const map = new Map({
  target: "map-container",
  layers: [
    new TileLayer({
      className: "bw",
      source: new OSM(),
    }),
    new VectorLayer({
      source: new VectorSource({
        format: new GeoJSON(),
        url: "./data/properties.geojson",
      }),
      style: ageStyle,
      ocerlaps: false,
    }),
  ],
  view: new View({
    center: transform(center, "EPSG:4326", "EPSG:3857"),
    zoom: 13,
  }),
});

const status = document.getElementById("status");
let selected = null;
map.on("pointermove", function (e) {
  if (selected !== null) {
    selected.setStyle(undefined);
    selected = null;
  }

  map.forEachFeatureAtPixel(e.pixel, function (f) {
    selected = f;
    f.setStyle(selectedStyle(ageStyle)(f));
    return true;
  });

  if (selected) {
    status.innerHTML = selected.get("YEAR_BUILT");
  } else {
    status.innerHTML = "&nbsp;";
  }
});
